import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, share, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ServiceProvider } from 'src/app/service';
import { AuthStoreSelectors } from '../auth-store';
import { State } from '../root-state';
import * as LocationStoreActions from './actions';
import * as LocationStoreSelectors from './selectors';
import { LocationStoreEntity } from './state';

@Injectable({ providedIn: 'root' })
export class LocationsStoreEffects implements OnInitEffects {
  constructor(private _store$: Store<State>, private _actions$: Actions, private _serviceProvider: ServiceProvider) {}

  ngrxOnInitEffects(): Action {
    return LocationStoreActions.Init();
  }

  init$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LocationStoreActions.Init),
      switchMap(() =>
        this._store$.select(AuthStoreSelectors.selectUserInfo).pipe(
          filter((u) => !!u && u.teamMemberId != 0),
          take(1)
        )
      ),
      map((user) => LocationStoreActions.LoadRequest({ teamMemberId: user.teamMemberId }))
    )
  );

  selectSuccessEffect$: Observable<Action> = createEffect(
    () =>
      this._actions$.pipe(
        ofType(LocationStoreActions.SelectSuccess),
        tap((action) => {
          sessionStorage.setItem('selectedLocationId', action.location.id.toString());
        })
      ),
    { dispatch: false }
  );

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(LocationStoreActions.LoadRequest),
      withLatestFrom(this._store$.select(LocationStoreSelectors.getSelectedLocationId)),
      switchMap(([action, selectedLocationId]) =>
        this._serviceProvider.get<LocationRequestDto>(`TeamSettings/LocationList`).pipe(
          switchMap((result) => {
            return result.data.length > 0 && (selectedLocationId == null || selectedLocationId == undefined)
              ? // Dispatch action to select first in list (or cached value) when at least one location is available and none is currently selected
                of(
                  LocationStoreActions.LoadSuccess({ locations: result.data }),
                  LocationStoreActions.SelectRequest({ id: this._getCachedLocationId() > 0 ? this._getCachedLocationId() : result.data[0].id })
                )
              : of(LocationStoreActions.LoadSuccess({ locations: result.data }));
          }),
          catchError((err: HttpErrorResponse) => of(LocationStoreActions.LoadFailure({ error: err.error })))
        )
      )
    )
  );

  selectRequestEffect$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(LocationStoreActions.SelectRequest),
      withLatestFrom(this._store$.select(LocationStoreSelectors.selectLocationsState)),
      switchMap(([action, state]) => {
        if (state.ids.length > 0 && state.entities[action.id]) {
          return of(LocationStoreActions.SelectSuccess({ location: state.entities[action.id] }));
        } else if (state.ids.length > 0 && action.id == null) {
          return of(LocationStoreActions.SelectSuccess({ location: state.entities[state.ids[0]] }));
        } else {
          return of(LocationStoreActions.SelectFailure({ error: 'No Locations loaded' }));
        }
      })
    )
  );

  updateRequestEffect = createEffect(() =>
    this._actions$.pipe(
      ofType(LocationStoreActions.UpdateRequest),
      switchMap((action) => {
        const data: LocationUpdateRequest = {
          ...action.location,
          practiceName: action.location.displayName,
          address: action.location.displayAddress,
          city: action.location.displayCity,
          email: action.location.displayEmail,
          phone: action.location.displayPhone,
          state: action.location.displayState,
          zip: action.location.displayZip,
        };
        return this._serviceProvider.post<LocationUpdateRequestResponse>(`OfficeInfo/OfficeDetailsUpdate/`, data).pipe(
          take(1),
          map((result) => {
            if (result.status) return LocationStoreActions.UpdateSuccess({ location: action.location });
            else return LocationStoreActions.UpdateFailure({ error: `Failure: ${result}` });
          }),
          catchError((err) => of(LocationStoreActions.UpdateFailure({ error: err })))
        );
      }),
      share()
    )
  );

  private _getCachedLocationId(): number {
    return Number(sessionStorage.getItem('selectedLocationId'));
  }
}

interface LocationRequestDto {
  authToken: string;
  data: LocationStoreEntity[];
  expirationTime: Date;
  msg: string;
  status: true;
}

interface LocationUpdateRequest {
  id: number;
  practiceName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  email: string;
}

interface LocationUpdateRequestResponse {
  status: boolean;
}
