import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mapTo, share, switchMap, take } from 'rxjs/operators';
import { CreateOrUpdateUserDetailsDto } from 'src/app/models/CreateOrUpdateUserDetailsDto';
import { ServiceProvider } from 'src/app/service';
import { TeamSettingsClient } from 'src/app/services/api.service';
import { RootStoreState } from '..';
import { AuthStoreSelectors } from '../auth-store';
import * as UserStoreActions from './actions';

@Injectable({ providedIn: 'root' })
export class UserStoreEffectsService implements OnInitEffects {
  constructor(
    private _actions$: Actions,
    private _http: ServiceProvider,
    private _teamSettingsClient: TeamSettingsClient,
    private _store$: Store<RootStoreState.State>
  ) {}

  ngrxOnInitEffects(): Action {
    return UserStoreActions.Init();
  }

  // Load Users list after login
  loginLoadRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserStoreActions.Init),
      switchMap(() =>
        this._store$.select(AuthStoreSelectors.selectUserInfo).pipe(
          filter((u) => !!u && u.teamMemberId != 0),
          take(1)
        )
      ),
      mapTo(UserStoreActions.LoadRequest())
    )
  );

  // Query API on load request
  loadRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserStoreActions.LoadRequest),
      switchMap(() =>
        this._teamSettingsClient.teamSettings_UserList().pipe(
          map((result) => UserStoreActions.LoadSuccess({ users: result.data })),
          catchError((error) => of(UserStoreActions.LoadFailure({ error })))
        )
      ),
      share()
    )
  );

  // Unlock a locked user (likely from too many failed logins)
  unlockRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserStoreActions.UnlockRequest),
      switchMap((action) =>
        this._http.post(`TeamSettings/UnlockTeamMember/${action.teamMemberId}`).pipe(
          map(() => UserStoreActions.UnlockSuccess({ teamMemberId: action.teamMemberId })),
          catchError((err) => of(UserStoreActions.UnlockFailure({ teamMemberId: action.teamMemberId, error: err })))
        )
      ),
      share()
    )
  );

  upsertUserRequestEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserStoreActions.UpsertRequest),
      switchMap((action) => {
        const formData = new FormData();
        for (const prop in action.user) {
          if (!Object.prototype.hasOwnProperty.call(action.user, prop) || action.user[prop] === null) {
            continue;
          }
          formData.append(prop, action.user[prop]);
        }

        return this._http.post<CreateOrUpdateUserDetailsDto>('TeamSettings/AddOrUpdateUserDetails', formData).pipe(
          map((result) => {
            return UserStoreActions.UpsertSuccess({ user: { ...action.user, id: result.teamMember.teamMemberId } });
          }),
          catchError((err: HttpErrorResponse) => of(UserStoreActions.UpsertFailure({ user: { ...action.user }, error: err.error.message })))
        );
      }),
      share()
    )
  );
}
