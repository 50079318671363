import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';
import { map, switchMap, take } from 'rxjs/operators';
import { ComponentBase } from 'src/app/core/ComponentBase';
import { EmergePayTransactionResponse } from 'src/app/models/EmergePayTransactionResponse';
import { RootStoreState, TenantSettingsStoreSelectors } from 'src/app/root-store';
import { TerminalClient, TransactionRequest, TransactionTypeEnum } from 'src/app/services/api.service';
import { EmergePayService } from 'src/app/services/emergepay.service';
import { EBizChargeService } from 'src/app/shared/ebizcharge/ebizcharge.service';
import { IEBizChargeTokenizeCardResponse } from 'src/app/shared/ebizcharge/IEBizChargeTokenizeCardResponse';

@Component({
  selector: 'app-create-auto-pay-dialog',
  templateUrl: './create-auto-pay-dialog.component.html',
  styleUrls: ['./create-auto-pay-dialog.component.scss'],
})
export class CreateAutoPayDialogComponent extends ComponentBase {
  public get isWorking$() {
    return this.__workingCount$;
  }

  private _paymentProcessor: 'GRAVITY' | 'EBIZCHARGE';

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: { ledgerId: number; patientId: number },
    private _dialogRef: MatDialogRef<CreateAutoPayDialogComponent>,
    private _terminalClient: TerminalClient,
    private _emergePayService: EmergePayService,
    private _ebizPayService: EBizChargeService,
    private _store$: Store<RootStoreState.State>
  ) {
    super();
    this.__subSink.sink = this._store$
      .select(TenantSettingsStoreSelectors.selectPaymentProcessor)
      .pipe(take(1))
      .subscribe((x) => (this._paymentProcessor = x));
  }

  submit(value: AddressFormValue): void {
    this.__working();
    const request = new TransactionRequest({
      ...value,
      transactionType: value.paymentMethod == 'CC' ? TransactionTypeEnum.CreditSaveCard : TransactionTypeEnum.AchSaveAccount,
    });
    this._terminalClient
      .terminal_GetLedgerTerminal(this._data.patientId, this._data.ledgerId, request)
      .pipe(
        switchMap((terminal) => {
          if (this._paymentProcessor == 'GRAVITY')
            return this._emergePayService.open(terminal.transactionToken).pipe(
              map((result) => {
                result.billingName = value.billingName; // Value is not returned by gravity so setting value for ourselves
                const location = { cardHolderAddress: value.billingAddress, zip: value.billingPostalCode };
                return <AutoPayDialogComponentResult>{ processor: 'GRAVITY', data: Object.assign(result, location) };
              })
            );
          else if (this._paymentProcessor == 'EBIZCHARGE')
            return this._ebizPayService
              .openCardTokenizationDialog(request, this._data.patientId, this._data.ledgerId)
              .pipe(map((x) => <AutoPayDialogComponentResult>{ processor: 'EBBIZCHARGE', data: { ...x, ...value } }));
        })
      )
      .subscribe(
        (result) => {
          this._dialogRef.close(result);
        },
        (result) => console.log('failure', result),
        () => this.__doneWorking()
      );
  }
}

type AddressFormValue = { billingName: string; billingAddress: string; billingPostalCode: string; paymentMethod: 'CC' | 'ACH' };
export type AutoPayDialogComponentResult =
  | { processor: 'GRAVITY'; data: EmergePayTransactionResponse & { cardHolderAddress: string; zip: string } }
  | { processor: 'EBBIZCHARGE'; data: IEBizChargeTokenizeCardResponse & AddressFormValue };
