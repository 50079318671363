import { createAction, props } from '@ngrx/store';
import { OpenIddictResponseDto, UserDetailsDto } from 'src/app/services/api.service';

export const Init = createAction('[Auth] Init');

export const Login = createAction('[Auth] Login', props<{ username: string; password: string }>());
export const LoginAuthSuccess = createAction('[Auth] LoginAuthSuccess', props<{ token: OpenIddictResponseDto }>());
export const LoginUserInfoSuccess = createAction('[Auth] LoginUserInfoSuccess', props<{ userInfo: UserDetailsDto }>());
export const LoginSuccess = createAction('[Auth] LoginSuccess', props<{ token: OpenIddictResponseDto; userInfo: UserDetailsDto }>());
export const LoginFailure = createAction('[Auth] LoginFailure', props<{ error: OpenIddictResponseDto | string }>());

export const LoginPatientSuccess = createAction('[Auth] LoginPatientSuccess', props<{ token: OpenIddictResponseDto; userInfo: UserDetailsDto }>());

export const RefreshToken = createAction('[Auth] RefreshToken');
export const RefreshTokenSuccess = createAction('[Auth] RefreshTokenSuccess', props<{ token: OpenIddictResponseDto }>());
export const RefreshTokenFailure = createAction('[Auth] RefreshTokenFailure', props<{ error: OpenIddictResponseDto | string }>());

export const Logout = createAction('[Auth] Logout');
export const ChangeClient = createAction('[Auth] ChangeClient', props<{ customerId: number; clientName: string; teamMemberId: number | null }>());
export const ChangeClientSucess = createAction('[Auth] ChangeClientSuccess');
